import React from "react";
import logo from "./Aligi-Logo.png";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <head>
        <title>Agili Business Page</title>
        <meta name="keywords" content="business, development, leath" />
        <meta
          name="description"
          content="Our Agili Business Page, to see what we are doing now, visit leathgroup.com"
        />
      </head>
      <header>
        <div className="content">
          <a
            className="App-link"
            href="https://www.leathgroup.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button aria-label="see our current business page">
              return to leathgroup.com
            </button>
          </a>
          <img src={logo} className="logo" alt="Aligi-logo" />
        </div>
      </header>
      <div className="content copy">
        <h1>Ideas are</h1>
        <h2>TRANSFORMATIVE</h2>
        <p>
          Faith, hope, love, freedom, liberty, justice, integrity, honor…these
          are the ideas (and ideals) for which humankind has lived, fought and
          died for millennia.
        </p>
        <p>
          Greek for positive transformation, Allagi Learning represented a
          season of personal creativity that spanned eighteen years (1992–2010)
          and resulted in some of the most meaningful writing of my career.
          Older and somewhat leavened by the wisdom that accompanies time, I
          have collapsed this pursuit and now nurture something simpler and
          singular: my namesake.
        </p>
        <p>
          In all its forms (be they organizations, people, places or political
          parties), ‘Allagi’ represents in the aggregate what we often seek in
          the particulate: a wave of inspiring and transforming ideas.
        </p>
        <p>
          If our lives are paintings, ideas are the paint and our behaviors are
          the brushstrokes. If our lives are stories, ideas are the letters,
          numbers and punctuations and our behaviors are the keystrokes. If our
          lives are songs, ideas are the notes and our behaviors are the
          movements.
        </p>
        <p>
          Our passions, principles, performance and potential are inextricably
          intertwined with our ideas.
        </p>
        <p>
          Here’s to a lifetime of thinking, doing and contributing—regardless
          the vessel.
        </p>
        <p>May the ideas be great ones, that the art prove worthwhile.</p>
      </div>
    </div>
  );
}

export default App;
